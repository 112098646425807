import React from 'react';
import PropTypes from 'prop-types';
import TrackVisibility from 'react-on-screen';

import styles from './footer.module.css';
import FooterContent from './FooterContent';
import { direccionOficina, direccionTiendaBellavista, horarioTiendaBellavista } from '../../lib/datos';

const scrollToTop = () => {
  window.scroll({
    top: 0,
    behavior: 'smooth',
  });
  window.location.hash = '';
};

const Footer = ({
  setContactFormVisible, noAnim, compact, children,
}) => (
  <footer className={styles.container} data-testid="footer">
    <TrackVisibility partialVisibility offset={-100}>
      <FooterContent
        scrollToTop={scrollToTop}
        setContactFormVisible={setContactFormVisible}
        noAnim={noAnim}
        compact={compact}
      >
        {children}
      </FooterContent>
    </TrackVisibility>
  </footer>
);

Footer.propTypes = {
  setContactFormVisible: PropTypes.func,
  noAnim: PropTypes.bool,
  compact: PropTypes.bool,
  children: PropTypes.node,
};

Footer.defaultProps = {
  setContactFormVisible: undefined,
  noAnim: false,
  compact: true,
  children: `
  **NUEVA TIENDA**: ${direccionTiendaBellavista}  
  ${horarioTiendaBellavista}
  
  **OFICINA**: ${direccionOficina} 
  (Recojo de compras o entrega de prendas **Previa Cita**)
    `,
};

export default Footer;
