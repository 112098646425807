import React from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

const ButtonSalir = ({ closeSidebar, classNames }) => {
  const router = useRouter();
  return (
    <Button
      variant="outlined"
      onClick={() => {
        closeSidebar();
        router.push('/auth/logout/');
      }}
      className={classNames}
      data-testid="button-logout"
    >
      Salir
    </Button>
  );
};

ButtonSalir.propTypes = {
  closeSidebar: PropTypes.func,
  classNames: PropTypes.string,
};

ButtonSalir.defaultProps = {
  closeSidebar: () => {},
  classNames: '',
};

export default ButtonSalir;
