import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Hidden from '@material-ui/core/Hidden';
import Img from 'next/image';
import { marked } from 'marked';

import { scrollTo } from '../../lib/utils';
import { socialIcons } from '../utils';
import Link from '../Link';
import styles from './footer.module.css';

import imgLogoWhite from '../../../public/images/logo-white.png';
import libroDeReclamacionesImg from '../../../public/images/libro-de-reclamaciones.png';
import { cmsIsEditing } from '../../features/cms/utils';

const FooterContent = ({
  isVisible,
  scrollToTop,
  setContactFormVisible,
  noAnim,
  compact,
  children,
}) => {
  const smUp = useMediaQuery('(min-width:600px)');
  const mdUp = useMediaQuery('(min-width:960px)');
  const logoW = compact ? 100 : 200;
  const logoH = (logoW * 358) / 850;

  const img = (
    <div
      onClick={scrollToTop}
      onKeyPress={scrollToTop}
      role="button"
      tabIndex="0"
      className="w-full mb-3"
    >
      <Img
        src={imgLogoWhite}
        alt="Logo de Las Traperas"
        width={logoW}
        height={logoH}
        className={styles.logo}
        layout="fixed"
      />
    </div>
  );

  const showContactForm = () => {
    if (setContactFormVisible) {
      setContactFormVisible(true);
      scrollTo('contacto');
    }
  };

  const renderSocialIcon = (i) => (
    <li key={i.name} className={mdUp ? '' : styles.socialLiSm}>
      <a
        href={i.url}
        target="_blank"
        rel="noopener noreferrer"
        className={clsx(styles.icon)}
      >
        <span className="sr-only">{i.aria}</span>
        <Icon className={clsx(i.icon)} style={{ fontSize: '1.75rem' }} />
      </a>
    </li>
  );

  const dondeEncontrarnos = useMemo(
    () => (
      // eslint-disable-next-line react/no-danger
      <div dangerouslySetInnerHTML={{ __html: marked.parse(children) }} />
    ),
    [children],
  );

  return (
    <Grid container justifyContent="center">
      {smUp && (
        <div className={styles.scrollToTop}>
          <div>
            {(noAnim || isVisible) && (
              <button
                key="button-top"
                type="button"
                className={styles.topButton}
                onClick={scrollToTop}
              >
                <span className="sr-only">Volver a la parte superior</span>
                <Icon
                  className={clsx('fas fa-angle-double-up')}
                  style={{ fontSize: 18 }}
                />
              </button>
            )}
          </div>
        </div>
      )}
      <Grid container item xs={12} md={10} alignItems="flex-end">
        {!mdUp && (
          <Grid
            container
            item
            xs={12}
            className={clsx(styles.footerCol, styles.textCenter)}
            direction="row"
          >
            {!compact ? (
              img
            ) : (
              <Grid
                container
                item
                xs={12}
                className={styles.dondeEncontrarnosCompact}
              >
                {img}
                {dondeEncontrarnos}
              </Grid>
            )}
          </Grid>
        )}
        <Grid item xs={12} className={clsx(styles.footerCol, styles.social)}>
          <div>
            {(noAnim || isVisible) && (
              <ul
                key="social-list"
                className={clsx({ [styles.notCompact]: !compact })}
              >
                {(setContactFormVisible
                  ? socialIcons
                  : socialIcons.slice(0, 2)
                ).map(renderSocialIcon)}
                {mdUp
                  && (!compact ? (
                    <li>{img}</li>
                  ) : (
                    <li>
                      <Grid container item xs={12} alignItems="center">
                        <Grid item xs={6} sm={4}>
                          {img}
                        </Grid>
                        <Grid
                          container
                          item
                          xs={6}
                          sm={8}
                          className={styles.dondeEncontrarnosCompact}
                        >
                          {dondeEncontrarnos}
                        </Grid>
                      </Grid>
                    </li>
                  ))}
                {setContactFormVisible && (
                  <li>
                    <a
                      href="#contacto"
                      onClick={showContactForm}
                      onKeyPress={showContactForm}
                      className={clsx('fab', styles.icon)}
                    >
                      <Icon
                        className={clsx('far fa-envelope')}
                        style={{ fontSize: '1.9rem' }}
                      />
                    </a>
                  </li>
                )}
                {!setContactFormVisible
                  && socialIcons.slice(2).map(renderSocialIcon)}
                <li className={clsx({ [styles.socialLiSm]: !mdUp })} />
                {setContactFormVisible && (
                  <Hidden smDown>
                    <li style={{ width: 60 }}>&nbsp;</li>
                  </Hidden>
                )}
              </ul>
            )}
          </div>
        </Grid>
        {!compact && (
          <Grid
            item
            xs={12}
            sm
            className={clsx(styles.footerCol, styles.textCenter)}
          >
            {dondeEncontrarnos}
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} className={styles.links}>
        <Link href="/politica-de-privacidad" className={styles.footerLink}>
          Política de Privacidad
        </Link>
        {' | '}
        <Link href="/politica-de-cookies" className={styles.footerLink}>
          Política de Cookies
        </Link>
        {' | '}
        <Link href="/terminos-y-condiciones" className={styles.footerLink}>
          Términos y condiciones
        </Link>
        {' | '}
        <Link href="/politica-de-devoluciones" className={styles.footerLink}>
          Política de devoluciones
        </Link>
        {' | '}
        <Link href="/contacto" className={styles.footerLink}>
          Contacto
        </Link>
        {cmsIsEditing() && (
          <>
            {' | '}
            <Link href="/admin" className={styles.footerLink}>
              CMS Admin
            </Link>
          </>
        )}
      </Grid>
      <Grid item xs={12} className={styles.links}>
        <Link href="/libro-de-reclamaciones" className={styles.footerLink}>
          <Img src={libroDeReclamacionesImg} alt="Libro de reclamaciones" className="hover:cursor-pointer" />
        </Link>
      </Grid>
    </Grid>
  );
};

FooterContent.propTypes = {
  isVisible: PropTypes.bool,
  scrollToTop: PropTypes.func.isRequired,
  setContactFormVisible: PropTypes.func,
  noAnim: PropTypes.bool.isRequired,
  compact: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

FooterContent.defaultProps = {
  isVisible: false,
  setContactFormVisible: undefined,
  compact: true,
};

export default FooterContent;
