import React from 'react';
import PropTypes from 'prop-types';

const Emoji = ({ ariaLabel, children, className }) => (
  <span className={className} role="img" aria-label={ariaLabel}>
    {children}
  </span>
);

Emoji.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Emoji.defaultProps = {
  className: undefined,
};

export default Emoji;
