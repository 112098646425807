import { useEffect, useRef, useCallback } from 'react';

function useInterval(callback, duration) {
  const savedCallback = useRef();
  const intervalId = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const tick = useCallback(() => {
    savedCallback.current();
  }, []);

  const start = useCallback(() => {
    if (duration !== null) {
      intervalId.current = setInterval(tick, duration);
      return () => clearInterval(intervalId.current);
    }
    return undefined;
  }, [duration, tick]);

  // Set up the interval.
  useEffect(() => start(), [start]);

  const reset = useCallback(() => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }
    return start();
  }, [start]);

  return reset;
}

export default useInterval;
