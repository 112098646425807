import React from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import useStyles from './useStyles';

import ButtonLT from '../utils/elements/ButtonLT';

const ButtonIngresa = ({ closeSidebar }) => {
  const router = useRouter();
  const classes = useStyles();
  return (
    <ButtonLT
      classes={{ root: classes.btnSecondary }}
      onClick={() => {
        closeSidebar();
        router.push(`/auth/login/?from=${window.location.pathname}`);
      }}
      data-testid="button-login"
    >
      INGRESA
    </ButtonLT>
  );
};

ButtonIngresa.propTypes = {
  closeSidebar: PropTypes.func,
};

ButtonIngresa.defaultProps = {
  closeSidebar: () => {},
};

export default ButtonIngresa;
