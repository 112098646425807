/* eslint-disable */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { InstantSearch } from 'react-instantsearch-dom';
import dynamic from 'next/dynamic';

import sliceApp from '../../lib/reducers/app';
import Header from '../Header';
import Seo from '../Seo';
import Footer from '../Footer';
import { useStore as useSearchStore, searchClient } from '../../features/search/store/main';
import config from '../../config';

const Snackbar = dynamic(() => import('../Snackbar'));
const Chat = dynamic(() => import('./Chat'));
const CookieConsentBar = dynamic(() => import('../CookieConsentBar'));
const PerfilFormModal = dynamic(() => import('../PerfilFormModal'));

const {
  actions: { setPerfilDatosModalOpen },
} = sliceApp;

const Layout = ({
  children,
  title,
  description,
  keywords,
  ldJson,
  setContactFormVisible,
  footerAnimation,
  footerCompact,
  image,
  widgetsCollector,
  footerContent,
}) => {
  const dispatch = useDispatch();

  const { perfilDatosModalOpen } = useSelector(
    (state) => state.app,
  );

  const { data: userData } = useSelector((state) => state.user);

  useEffect(() => {
    if (userData && !window.localStorage.getItem('perfilFormHide')) {
      dispatch(setPerfilDatosModalOpen(true));
    }
  }, [dispatch, userData]);

  const {
    indexName, searchState, resultsState, onSearchStateChange,
  } = useSearchStore();
  
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={indexName}
      searchState={searchState}
      resultsState={resultsState}
      onSearchStateChange={onSearchStateChange}
      widgetsCollector={widgetsCollector}
    >
      <Seo
        title={title}
        keywords={keywords}
        description={description}
        ldJson={ldJson}
        image={image}
      />
      <Header siteTitle={config.title} />
      {children}

      <Chat />

        <Footer
          setContactFormVisible={setContactFormVisible}
          noAnim={!footerAnimation}
          compact={footerCompact}
        >
          {footerContent}
        </Footer>


      <Snackbar />
      <CookieConsentBar />
      {perfilDatosModalOpen && <PerfilFormModal />}
    </InstantSearch>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string.isRequired),
  ldJson: PropTypes.object,
  setContactFormVisible: PropTypes.func,
  footerAnimation: PropTypes.bool,
  footerCompact: PropTypes.bool,
  image: PropTypes.string,
  widgetsCollector: PropTypes.func,
};

Layout.defaultProps = {
  description: '',
  keywords: [],
  ldJson: undefined,
  setContactFormVisible: undefined,
  footerAnimation: false,
  footerCompact: true,
  image: '',
  widgetsCollector: undefined,
};

const LayoutWithRedux = Layout;

export default LayoutWithRedux;
