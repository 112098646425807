import { useState, useEffect } from 'react';

let theHub;
let onAuthChange;

const useCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    import('@aws-amplify/core')
      .then(({ Hub }) => {
        theHub = Hub;
        import('@aws-amplify/auth').then(({ Auth }) => {
          onAuthChange = ({ channel }) => {
            if (channel === 'auth') {
              Auth.currentAuthenticatedUser()
                .then((user) => {
                  setCurrentUser(user);
                }).catch(() => {
                  setCurrentUser(null);
                });
            }
          };
          onAuthChange({ channel: 'auth' });
          Hub.listen('auth', onAuthChange);
        });
      });
    if (theHub && onAuthChange) {
      return theHub.remove('auth', onAuthChange);
    }
  }, []);
  return currentUser;
};

export default useCurrentUser;
