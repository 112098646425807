const siteAddress = new URL('https://www.lastraperas.com');

const siteConfig = {
  title: 'Las Traperas',
  description:
    'Consume responsable. Facilitamos que se re-circulen las prendas que todas tenemos en perfecto estado y no usamos.',
  siteUrl: siteAddress.href,
  author: 'Las Traperas',
  social: {
    twitter: 'lastraperasperu',
  },
  keywords: [
    'las traperas',
    'consumo responsable',
    'segunda mano',
    'second hand',
    'peru',
    'lima',
    'barranco',
    'vintage',
    'thrift shop',
    'moda sostenible',
    'sustainable fashion',
    'startup',
    'tienda online ropa',
    'ropa niños',
    'ropa niñas',
    'ropa bebé',
    'ropa mujer',
    'ropa barata',
    'ropa en oferta',
    'rebajas',
    'outlet',
  ],
};

export default siteConfig;
