import { makeStyles } from '@material-ui/core/styles';
import {
  colorPrimary,
  colorBgPrimary,
  colorBgSecondary,
} from '../utils/elements/muiStyles';

export default makeStyles((theme) => ({
  iconButton: {
    padding: 0,
  },
  container: {
    paddingTop: 15,
    paddingBottom: 10,
    [theme.breakpoints.up('md')]: {
      paddingTop: 40,
    },
  },
  logoContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
      overflow: 'visible',
    },
  },
  logo: {
    border: '1px solid #000',
    borderRadius: '50%',
    margin: 10,
  },
  marginAuto: {
    margin: '0 auto',
  },
  textCenter: {
    textAlign: 'center',
  },
  textRight: {
    textAlign: 'right',
  },
  bgSecondary: {
    backgroundColor: colorBgSecondary,
  },
  borderRight: {
    //   borderRight: 'border border-solid border-primary',
    borderRight: `1px solid ${colorBgSecondary}`,
  },
  padding: {
    padding: '12px 0px',
  },
  headerMenuMobile: {
    display: 'inline-block',
    padding: 4,
  },
  shopButton: {
    width: '100%',
  },
  menuList: {
    zIndex: 3000,
  },
  leftLauncher: {
    flexBasis: '35%',
    [theme.breakpoints.up('lg')]: {
      flexBasis: '30%',
    },
  },
  menuItem: {
    padding: 12,
  },
  btnSecondary: {
    backgroundColor: colorBgPrimary,
    border: `2px solid ${colorPrimary}`,
    fontWeight: 'bold',
  },
  link: {
    paddingTop: 10,
    paddingBottom: 10,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
  },
}));
