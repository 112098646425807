import React from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import useStyles from './useStyles';

const ButtonRegistrate = ({ closeSidebar }) => {
  const router = useRouter();
  const classes = useStyles();
  return (
    <Button
      classes={{ root: classes.btnSecondary }}
      onClick={() => {
        closeSidebar();
        router.push(`/auth/signup/?from=${window.location.pathname}`);
      }}
    >
      REGÍSTRATE
    </Button>
  );
};

ButtonRegistrate.propTypes = {
  closeSidebar: PropTypes.func,
};

ButtonRegistrate.defaultProps = {
  closeSidebar: () => {},
};

export default ButtonRegistrate;
