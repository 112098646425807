import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

import useSiteMetadata from '../../hooks/useSiteMetadata';

const defaultImagePath = '/public/images/seo/tienda-online-ropa-mujer.jpg';
const Seo = ({
  description, lang, keywords, title, ldJson, image,
}) => {
  const siteMetadata = useSiteMetadata();
  // const { pathname } = useRouter();

  const metaDescription = description || siteMetadata.description;
  const metaKeywords = keywords.concat(siteMetadata.keywords);
  const metaImage = image || `${siteMetadata.siteUrl}${defaultImagePath}`;

  const siteTitle = siteMetadata.title;

  return (
    <Head lang={lang}>
      <title>{`${title} | ${siteTitle}`}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      {/* <meta property="og:url" content={`${siteMetadata.siteUrl}${pathname}`} /> */}
      <meta property="og:description" content={metaDescription} />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:image" content={metaImage} />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:creator" content={siteMetadata.social.twitter} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={metaDescription} />
      <meta property="twitter:image" content={metaImage} />
      {ldJson && (
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      )}
      {metaKeywords.length && (
        <meta property="keywords" content={metaKeywords.join(', ')} />
      )}
    </Head>
  );
};

Seo.defaultProps = {
  lang: 'es',
  keywords: [],
  description: '',
  ldJson: undefined,
  image: undefined,
};

Seo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  lang: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  ldJson: PropTypes.object,
  image: PropTypes.string,
};

export default Seo;
