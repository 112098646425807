import React from 'react';
import PropTypes from 'prop-types';
import NextLink from 'next/link';

import { trackNavigation } from '../../lib/utils/analytics';
import { omit } from '../../lib/utils/lodashFunctions';

const doTrack = (to, replace, state) => {
  let opts;
  if (replace || replace === false || state) {
    opts = {};
    if (replace || replace === false) {
      opts.replace = replace;
    }
    if (state) {
      opts.state = state;
    }
  }
  trackNavigation(to, opts);
};

const Link = (props) => {
  const {
    href, replace, state, track, onClick,
  } = props;
  return (
    <NextLink
      {...omit(props, ['track', 'onClick'])}
      onClick={(...args) => {
        if (track !== false) {
          doTrack(href, replace, state);
        }
        if (onClick) {
          onClick(...args);
        }
      }}
    />
  );
};

Link.propTypes = {
  href: PropTypes.string.isRequired,
  replace: PropTypes.bool,
  state: PropTypes.object,
  onClick: PropTypes.func,
  track: PropTypes.bool,
};

Link.defaultProps = {
  replace: undefined,
  state: undefined,
  onClick: undefined,
  track: true,
};

export default Link;
