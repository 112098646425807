export const direccionOficina = 'Perez Roca 127, timbre 301 ok, Barranco, Lima';
export const coordsOficina = {
  lat: -12.1424447,
  lng: -77.0225622,
};

export const direccionTiendaBellavista = 'Mall Plaza Bellavista 2do piso (a 20 metros de Falabella)';
export const coordsTiendaBellavista = {
  lat: -12.0556177,
  lng: -77.1019947,
};
export const horarioTiendaBellavista = 'Lunes a domingo de 10am a 10pm';
