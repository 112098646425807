import {
  ITEM_ONLINE_FILTER_CATEGORIA,
  ITEM_CATEGORIA,
  ITEM_ONLINE_INFANTE_CATEGORIA,
  ITEM_ONLINE_INFANTE_TALLA,
} from './GQL_ENUMS';

const icons = '/images/iconos/spritesheet.png';

export const mujerCategoriasObjects = [
  {
    name: ITEM_ONLINE_FILTER_CATEGORIA.ACCESORIOS_BIJOUTERIE,
    label: 'Bijouterie',
    category: 'bijouterie',
    icon: icons,
    position: '-205px -55px',
    path: 'accesorios-bijouterie',
  },
  {
    name: ITEM_ONLINE_FILTER_CATEGORIA.ACCESORIOS,
    label: 'Accesorios',
    category: 'accesorios',
    icon: icons,
    position: '-155px -55px',
    path: 'accesorios',
  },
  {
    name: ITEM_ONLINE_FILTER_CATEGORIA.CARTERA_BOLSO,
    label: 'Cartera / Bolso',
    category: 'cartera bolso',
    icon: icons,
    position: '-55px -105px',
    path: 'accesorios-cartera-bolso',
  },
  {
    name: ITEM_ONLINE_FILTER_CATEGORIA.BLUSA_CAMISA,
    label: 'Blusa / Camisa',
    category: 'blusa',
    icon: icons,
    position: '-255px -205px',
    path: 'blusa-camisa',
  },
  {
    name: ITEM_ONLINE_FILTER_CATEGORIA.CHOMPA,
    label: 'Chompa / Cardigan',
    category: 'chompa',
    icon: icons,
    position: '-55px -205px',
    path: 'chompa-cardigan',
  },
  {
    name: ITEM_ONLINE_FILTER_CATEGORIA.CASACA,
    label: 'Casaca / Chaleco',
    category: 'casaca',
    icon: icons,
    position: '-355px -155px',
    path: 'casaca-chaleco',
  },
  {
    name: ITEM_ONLINE_FILTER_CATEGORIA.FALDA,
    label: 'Falda',
    category: 'falda',
    icon: icons,
    position: '-105px -155px',
    path: 'falda',
  },
  {
    name: ITEM_ONLINE_FILTER_CATEGORIA.PANTALON,
    label: 'Pantalón',
    category: 'pantalon',
    icon: icons,
    position: '-205px -155px',
    path: 'pantalon',
  },
  {
    name: ITEM_ONLINE_FILTER_CATEGORIA.PANTALON_JEAN,
    label: 'Pantalón jean',
    category: 'pantalon jean',
    icon: icons,
    position: '-305px -105px',
    path: 'pantalon-jean',
  },
  {
    name: ITEM_ONLINE_FILTER_CATEGORIA.POLO,
    label: 'Polo',
    category: 'polo polito',
    icon: icons,
    position: '-5px -255px',
    path: 'polo',
  },
  {
    name: ITEM_ONLINE_FILTER_CATEGORIA.SACO,
    label: 'Saco',
    category: 'saco',
    icon: icons,
    position: '-205px -205px',
    path: 'saco',
  },
  {
    name: ITEM_ONLINE_FILTER_CATEGORIA.SHORT,
    label: 'Short',
    category: 'short',
    icon: icons,
    position: '-255px -155px',
    path: 'short',
  },
  {
    name: ITEM_ONLINE_FILTER_CATEGORIA.VESTIDO,
    label: 'Vestido / enterizo',
    category: 'vestido',
    icon: icons,
    position: '-255px -255px',
    path: 'vestido',
  },
  {
    name: ITEM_ONLINE_FILTER_CATEGORIA.ZAPATOS,
    label: 'Zapatos',
    category: 'zapatos',
    icon: icons,
    position: '-305px -255px',
    path: 'zapatos',
  },
  {
    name: ITEM_ONLINE_FILTER_CATEGORIA.OTROS,
    label: 'Otros',
    category: 'otros',
    icon: icons,
    position: '-55px -155px',
    path: 'otros',
  },
  {
    name: ITEM_CATEGORIA.CHOMPITA,
    label: 'Chompita',
    category: 'chompita',
    icon: icons,
    position: '-105px -205px',
  },
  {
    name: ITEM_CATEGORIA.CHALECO,
    label: 'Chaleco',
    category: 'chaleco',
    icon: icons,
    position: '-355px -205px',
  },
  {
    name: ITEM_CATEGORIA.POLERA_SWEATSHIRT,
    label: 'Polera sweatshirt',
    category: 'polera sweatshirt',
    icon: icons,
    position: '-55px -255px',
  },
  {
    name: ITEM_CATEGORIA.CARDIGAN,
    label: 'Cardigan',
    category: 'cardigan',
    icon: icons,
    position: '-305px -155px',
  },
  {
    name: ITEM_CATEGORIA.PANUELO_BUFANDA_CHALINA,
    label: 'Panuelo bufanda chalina',
    category: 'panuelo bufanda chalina',
    icon: icons,
    position: '-255px -55px',
  },
  {
    name: ITEM_CATEGORIA.GORRO_SOMBRERO,
    label: 'Gorro Sombrero',
    category: 'gorro sombrero',
    icon: icons,
    position: '-355px -55px',
  },
  {
    name: ITEM_CATEGORIA.BILLETERA_MONEDERO,
    label: 'Billetera monedero',
    category: 'billetera monedero',
    icon: icons,
    position: '-205px -105px',
  },
  {
    name: ITEM_CATEGORIA.MOCHILA_MALETIN_CANGURO,
    label: 'Mochila maletin canguro',
    category: 'mochila maletin canguro',
    icon: icons,
    position: '-155px -105px',
  },
  {
    name: ITEM_CATEGORIA.LEGGINGS_JEGGINGS,
    label: 'Leggings jeggings',
    category: 'leggings jeggings',
    icon: icons,
    position: '-155px -155px',
  },
  {
    name: ITEM_CATEGORIA.CHAL_PUNTA_CAPA_PONCHO,
    label: 'Chal punta capa poncho',
    category: 'chal punta capa poncho',
    icon: icons,
    position: '-5px -205px',
  },
  {
    name: ITEM_CATEGORIA.SASTRE_CONJUNTO,
    label: 'Sastre conjunto',
    category: 'sastre conjunto',
    icon: icons,
    position: '-205px -205px',
  },
  {
    name: ITEM_CATEGORIA.TOP_BVD,
    label: 'Top bvd',
    category: 'top bvd',
    icon: icons,
    position: '-305px -205px',
  },
  {
    name: ITEM_CATEGORIA.ENTERIZO,
    label: 'Enterizo',
    category: 'enterizo',
    icon: icons,
    position: '-205px -255px',
  },
  {
    name: ITEM_CATEGORIA.ROPA_DE_BANO,
    label: 'Ropa de baño',
    category: 'ropa de bano',
    icon: icons,
    position: '-105px -255px',
  },
  {
    name: ITEM_CATEGORIA.ROPA_DE_DEPORTE,
    label: 'Ropa de deporte',
    category: 'ropa de deporte',
    icon: icons,
    position: '-255px -105px',
  },
];

export const categoryLabel = (cat, categoryObjects) => (
  categoryObjects.filter((element) => element.name === cat)
);

export const categoryInfoObject = (cat, categoryObjects) => (
  categoryObjects.filter((el) => cat && (cat.includes(el.category) || cat.includes(el.name)))
);

const fillOptions = (min, max) => {
  const x = max - min + 1;
  return Array.from(new Array(x), (_, i) => ({
    label: (i + min).toString(),
    value: (i + min).toString(),
  }));
};

export const TALLAS = {
  arriba: {
    label: 'Arriba',
    categorias: [
      ITEM_ONLINE_FILTER_CATEGORIA.BLUSA_CAMISA,
      ITEM_ONLINE_FILTER_CATEGORIA.CHOMPA,
      ITEM_ONLINE_FILTER_CATEGORIA.CASACA,
      ITEM_ONLINE_FILTER_CATEGORIA.FALDA,
      ITEM_ONLINE_FILTER_CATEGORIA.POLO,
      ITEM_ONLINE_FILTER_CATEGORIA.SACO,
      ITEM_ONLINE_FILTER_CATEGORIA.VESTIDO,
    ],
    values: [
      {
        label: 'XXS',
        value: 'xxs',
      },
      {
        label: 'XS',
        value: 'xs',
      },
      {
        label: 'S',
        value: 's',
      },
      {
        label: 'M',
        value: 'm',
      },
      {
        label: 'L',
        value: 'l',
      },
      {
        label: 'XL',
        value: 'xl',
      },
      {
        label: 'XXL',
        value: 'xxl',
      },
    ],
  },
  cintura: {
    label: 'Pantalón',
    categorias: [
      ITEM_ONLINE_FILTER_CATEGORIA.PANTALON,
      ITEM_ONLINE_FILTER_CATEGORIA.SHORT,
    ],
    values: fillOptions(24, 40),
  },
  calzado: {
    label: 'Calzado',
    categorias: [ITEM_ONLINE_FILTER_CATEGORIA.ZAPATOS],
    values: fillOptions(34, 42),
  },
  unica: {
    label: 'Otras',
    categorias: [
      ITEM_ONLINE_FILTER_CATEGORIA.ACCESORIOS,
      ITEM_ONLINE_FILTER_CATEGORIA.ACCESORIOS_BIJOUTERIE,
      ITEM_ONLINE_FILTER_CATEGORIA.CARTERA_BOLSO,
      ITEM_ONLINE_FILTER_CATEGORIA.OTROS,
    ],
    values: [
      {
        label: 'Única',
        value: 'u',
      },
    ],
  },
};

export const ENVIO_TIPO = {
  RECOJO_TIENDA: 'RECOJO_TIENDA',
  ENVIO: 'ENVIO',
};

export const PAGO_TIPO = {
  TARJETA: 'PAGO_CON_TARJETA',
  DEPOSITO: 'DEPÓSITO',
  TRANSFERENCIA: 'TRANSFERENCIA_POR_INTERNET',
};

export const ROPA = [
  ITEM_ONLINE_FILTER_CATEGORIA.BLUSA_CAMISA,
  ITEM_ONLINE_FILTER_CATEGORIA.CHOMPA,
  ITEM_ONLINE_FILTER_CATEGORIA.CASACA,
  ITEM_ONLINE_FILTER_CATEGORIA.FALDA,
  ITEM_ONLINE_FILTER_CATEGORIA.POLO,
  ITEM_ONLINE_FILTER_CATEGORIA.SACO,
  ITEM_ONLINE_FILTER_CATEGORIA.VESTIDO,
  ITEM_ONLINE_FILTER_CATEGORIA.PANTALON,
  ITEM_ONLINE_FILTER_CATEGORIA.SHORT,
];
export const ACCESORIOS = [
  ITEM_ONLINE_FILTER_CATEGORIA.ACCESORIOS_BIJOUTERIE,
  ITEM_ONLINE_FILTER_CATEGORIA.CARTERA_BOLSO,
  ITEM_ONLINE_FILTER_CATEGORIA.ZAPATOS,
  ITEM_ONLINE_FILTER_CATEGORIA.ACCESORIOS,
];

export const ITEM_ONLINE_INFANTE_FILTER_CATEGORIA = {
  ABRIGO_CASACA_CHALECO: ITEM_ONLINE_INFANTE_CATEGORIA.ABRIGO_CASACA_CHALECO,
  BLUSA_CAMISA_TOP: ITEM_ONLINE_INFANTE_CATEGORIA.BLUSA_CAMISA_TOP,
  BODY_MANGA_CORTA: ITEM_ONLINE_INFANTE_CATEGORIA.BODY_MANGA_CORTA,
  CONJUNTO: ITEM_ONLINE_INFANTE_CATEGORIA.CONJUNTO,
  GORRO_SOMBRERO: ITEM_ONLINE_INFANTE_CATEGORIA.GORRO_SOMBRERO,
  PANTALON_CON_PIE: ITEM_ONLINE_INFANTE_CATEGORIA.PANTALON_CON_PIE,
  PIJAMA: ITEM_ONLINE_INFANTE_CATEGORIA.PIJAMA,
  PLAYA: ITEM_ONLINE_INFANTE_CATEGORIA.PLAYA,
  VESTIDO: ITEM_ONLINE_INFANTE_CATEGORIA.VESTIDO,
  ZAPATOS: ITEM_ONLINE_INFANTE_CATEGORIA.ZAPATOS,
  OTROS: ITEM_ONLINE_INFANTE_CATEGORIA.OTROS,
};

export const infantesCategoriasObjects = [
  {
    name: 'ABRIGO_CASACA_CHALECO',
    label: 'Abrigo',
    labelIcon: icons,
    positionLabel: '-355px -255px',
    category: 'abrigo casaca chaleco',
    icon: icons,
    position: '-5px -5px',
    description: 'Sacos, casacas, chalecos, chompas y poleras',
    path: 'abrigo',
  },
  {
    name: 'BLUSA_CAMISA_TOP',
    label: 'Partes de arriba',
    labelIcon: icons,
    positionLabel: '-55px -305px',
    category: 'blusa camisa top',
    icon: icons,
    position: '-105px -5px',
    description: 'Camisas, blusas, tops y polos',
    path: 'partes-de-arriba',
  },
  {
    name: 'BODY_MANGA_CORTA',
    label: 'Bodies',
    labelIcon: icons,
    positionLabel: '-105px -305px',
    category: 'body manga corta',
    icon: icons,
    position: '-155px -5px',
    description: 'Bodies manga larga y corta',
    path: 'bodies',
  },
  {
    name: 'CONJUNTO',
    label: 'Conjuntos',
    labelIcon: icons,
    positionLabel: '-155px -305px',
    category: 'conjunto',
    icon: icons,
    position: '-355px -5px',
    path: 'conjuntos',
  },
  {
    name: 'GORRO_SOMBRERO',
    label: 'Accesorios',
    labelIcon: icons,
    positionLabel: '-5px -305px',
    category: 'gorro sombrero',
    icon: icons,
    position: '-105px -55px',
    description: 'Gorros, sombreros, medias',
    path: 'accesorios',
  },
  {
    name: 'PANTALON_CON_PIE',
    label: 'Partes de abajo',
    labelIcon: icons,
    positionLabel: '-255px -305px',
    category: 'pantalon con pie',
    icon: icons,
    position: '-205px -355px',
    description: 'Pantalones, jeans, faldas y shorts',
    path: 'partes-de-abajo',
  },
  {
    name: 'PIJAMA',
    label: 'Pijamas',
    labelIcon: icons,
    positionLabel: '-305px -305px',
    category: 'pijama',
    icon: icons,
    position: '-355px -355px',
    path: 'pijamas',
  },
  {
    name: 'PLAYA',
    label: 'Playa',
    labelIcon: icons,
    positionLabel: '-355px -305px',
    category: 'playa',
    icon: icons,
    position: '-355px -305px',
    path: 'playa',
  },
  {
    name: 'VESTIDO',
    label: 'Vestidos y enterizos',
    labelIcon: icons,
    positionLabel: '-5px -355px',
    category: 'vestido',
    icon: icons,
    position: '-5px -355px',
    description: 'Vestidos, enterizos, rompers y overoles',
    path: 'vestidos-enterizos',
  },
  {
    name: 'ZAPATOS',
    label: 'Zapatos y zapatillas',
    labelIcon: icons,
    positionLabel: '-55px -355px',
    category: 'zapatos',
    icon: icons,
    position: '-405px -255px',
    path: 'zapatos-zapatillas',
  },
  {
    name: 'OTROS',
    label: 'Otros',
    labelIcon: icons,
    positionLabel: '-205px -305px',
    category: 'otros',
    icon: icons,
    position: '-155px -355px',
    path: 'otros',
  },
  // CATEGORIAS ORIGINALES
  {
    name: 'BODY_MANGA_LARGA',
    label: 'Body manga larga',
    category: 'body manga larga',
    icon: icons,
    position: '-205px -5px',
  },
  {
    name: 'CHOMPA_POLERA',
    label: 'Chompa / Polera',
    category: 'chompa',
    icon: icons,
    position: '-305px -5px',
  },
  {
    name: 'FALDA',
    label: 'Falda',
    category: 'falda',
    icon: icons,
    position: '-55px -55px',
  },
  {
    name: 'SHORT',
    label: 'Short',
    category: 'short',
    icon: icons,
    position: '-405px -155px',
  },
  {
    name: 'MEDIAS_PANTY',
    label: 'Medias panty',
    category: 'medias panty',
    icon: icons,
    position: '-105px -355px',
  },
  {
    name: 'PANTALON_SIN_PIE',
    label: 'Pantalón sin pie',
    category: 'pantalon sin pie',
    icon: icons,
    position: '-305px -355px',
  },
  {
    name: 'PANTALON_JEAN',
    label: 'Pantalón jean',
    category: 'pantalon jean',
    icon: icons,
    position: '-255px -355px',
  },
  {
    name: 'POLO_MANGA_LARGA',
    label: 'Polo manga larga',
    category: 'polo manga larga',
    icon: icons,
    position: '-405px -55px',
  },
  {
    name: 'POLO_MANGA_CORTA',
    label: 'Polo manga corta',
    category: 'polo manga corta',
    icon: icons,
    position: '-405px -105px',
  },
  {
    name: 'ENTERIZO',
    label: 'Enterizo',
    category: 'enterizo',
    icon: icons,
    position: '-5px -55px',
  },
];

export const TALLAS_INFANTES = {
  ropa: {
    label: 'Ropa',
    categorias: Object.keys(ITEM_ONLINE_INFANTE_FILTER_CATEGORIA).filter(
      (c) => c !== ITEM_ONLINE_INFANTE_CATEGORIA.ZAPATOS,
    ),
    values: Object.keys(ITEM_ONLINE_INFANTE_TALLA)
      .slice(0, 13)
      .map((t) => ({
        label: t
          .toLowerCase()
          .replace(/_/gi, ' ')
          .trim()
          .replace('t', 'T')
          .replace(' a ', '-')
          .replace(' meses', 'M'),
        value: t,
      })),
  },
  calzado: {
    label: 'Calzado',
    categorias: [ITEM_ONLINE_INFANTE_CATEGORIA.ZAPATOS],
    values: Object.keys(ITEM_ONLINE_INFANTE_TALLA)
      .slice(0, 2)
      .map((t) => ({
        label: t
          .toLowerCase()
          .replace(/_/gi, ' ')
          .replace(' a ', '-')
          .replace(' meses', 'M'),
        value: t,
      }))
      .concat(
        Object.keys(ITEM_ONLINE_INFANTE_TALLA)
          .slice(15, 34)
          .map((t) => ({
            label: t.toLowerCase().replace(/_/gi, ' '),
            value: t,
          })),
      ),
  },
};

export const tallasMujer = ['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl', 'u'];
export const tallasInfante = Object.keys(ITEM_ONLINE_INFANTE_TALLA).map((t) => (
  t.toLowerCase()
    .replace(/^_/, '').replace(/_/g, ' ').replace('meses', ' ')
    .replace(' a ', '-')
    .toUpperCase()
    .trim()));
