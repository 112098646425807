import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Link from 'next/link';
import Img from 'next/image';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { MenuIcon } from '@heroicons/react/outline';
import dynamic from 'next/dynamic';

import slice from '../../lib/reducers/app';
import {
  SHOP_PAGE_PATHNAME,
  SHOP_INFANTE_PAGE_PATHNAME,
} from '../../lib/CONST';
import {
  ROPA,
  ACCESORIOS,
  ITEM_ONLINE_INFANTE_FILTER_CATEGORIA,
} from '../../lib/filterLabels';
import useCurrentUser from '../../features/auth/hooks/useCurrentUser';
import styles from './subHeader.module.css';
import ButtonRegistrate from './ButtonRegistrate';
import ButtonIngresa from './ButtonIngresa';
import ButtonSalir from './ButtonSalir';
import SuperHeader from './SuperHeader';
import SearchBox from './SearchBox';

import imgLogoSinTexto from '../../../public/images/logo-sin-texto.png';

const ShoppingCartMenu = dynamic(() => import('../ShoppingCartMenu'));
const SidebarMobile = dynamic(() => import('./SidebarMobile'));

const isUser = [
  {
    name: 'Mi Perfil',
    url: '/perfil/historial-de-puntos',
  },
];

const pages = [
  {
    name: '¿Cómo funciona?',
    url: '/como-funciona',
  },
  {
    name: 'Sobre nosotras',
    url: '/sobre-nosotras',
  },
  {
    name: 'Contacto',
    url: '/contacto',
  },
];

const {
  actions: { setSidebarVisible },
} = slice;

const Header = () => {
  const isMdUp = useMediaQuery('(min-width:1024px)');
  const { sidebarVisible } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const currUser = useCurrentUser();

  const [activePage, setActivePage] = useState({});

  useEffect(() => {
    const { pathname } = window.location;

    const ropaLower = ROPA.map(
      (s) => `${SHOP_PAGE_PATHNAME}/${s.toLowerCase().replace('_', '-')}`,
    );
    const accesoriosLower = ACCESORIOS.map(
      (s) => `${SHOP_PAGE_PATHNAME}/${s.toLowerCase().replace('_', '-')}`,
    );
    const infantesLower = Object.keys(ITEM_ONLINE_INFANTE_FILTER_CATEGORIA).map(
      (s) => `${SHOP_INFANTE_PAGE_PATHNAME}/${s.toLowerCase().replace('_', '-')}`,
    );
    [
      'shop/lo-ultimo',
      'shop/popular',
      'shop/donar-para-ayudar',
      'sobre-nosotras',
      'como-funciona',
      'shop/sale',
      'shop-ninxs/sale',
      ...ropaLower,
      ...accesoriosLower,
    ].forEach((path) => {
      if (pathname.includes(path)) {
        const ap = { [path]: true };
        if (ropaLower.includes(path)) {
          ap.ropa = true;
        }
        if (accesoriosLower.includes(path)) {
          ap.accesorios = true;
        }
        if (infantesLower.includes(path)) {
          ap.infantes = true;
        }
        setActivePage(ap);
      }
    });
  }, []);

  return (
    <div className={isMdUp ? 'header' : 'header-mobile'}>
      <SuperHeader />
      {isMdUp
        ? (
          <div
            className="
              grid grid-cols-md-header
              border-b border-b-solid border-b-bgSecondary
            "
          >
            <div className="flex flex-row items-center pt-1 ml-4">
              <Link href="/" passHref>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a>
                  <Img
                    src={imgLogoSinTexto}
                    width={135}
                    height={57}
                    layout="fixed"
                    className="mb-0"
                    alt="Logo de Las Traperas"
                  />
                </a>
              </Link>
            </div>
            <div className="flex flex-row items-center">
              <SearchBox />
            </div>
            <div className="flex justify-around items-center ml-3">
              <div
                className={clsx({
                  'header-menu-item': true,
                  [styles.headerDecoratedLink]: true,
                  [styles.active]: activePage['sobre-nosotras'],
                })}
              >
                <Link href="/sobre-nosotras#">Sobre nosotras</Link>
              </div>
              <div
                className={clsx({
                  'header-menu-item': true,
                  [styles.headerDecoratedLink]: true,
                  [styles.active]: activePage['como-funciona'],
                })}
              >
                <Link href="/como-funciona#">¿Cómo funciona?</Link>
              </div>
            </div>
            <div className="flex flex-row justify-between items-center">
              {!currUser ? (
                <>
                  <div className="header-menu-item">
                    <ButtonRegistrate />
                  </div>
                  <p className="text-base mb-0">
                    ó
                  </p>
                  <div className="header-menu-item">
                    <ButtonIngresa />
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={clsx([
                      'header-menu-item',
                      styles.headerDecoratedLink,
                    ])}
                  >
                    <Link href="/perfil/historial-de-puntos">Mi Perfil</Link>
                  </div>
                  <div className="header-menu-item">
                    <ButtonSalir />
                  </div>
                </>
              )}
            </div>
          </div>
        )
        : (
          <>
            <div
              className="
              grid grid-cols-header
              border-b border-b-solid border-b-bgSecondary
            "
            >
              <div className="pt-3">
                <Link href="/" passHref>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className="pl-2" aria-label="Ir al Home">
                    <Img
                      src={imgLogoSinTexto}
                      width={135 / 2.5}
                      height={57 / 2.5}
                      layout="fixed"
                      alt="Logo de Las Traperas"
                    />
                  </a>
                </Link>
              </div>
              <SearchBox />
              <ShoppingCartMenu id="shoppingCartHeader" />
              <button
                type="button"
                className="items-center"
                data-testid="header-sidebar-button"
                onClick={() => dispatch(setSidebarVisible(!sidebarVisible))}
              >
                <span className="sr-only">Abrir menú</span>
                <MenuIcon className="h-6 w-6 m-0" aria-hidden="true" />
              </button>
            </div>
            <SidebarMobile
              isOpen={sidebarVisible}
              navItems={!currUser ? pages : pages.concat(isUser)}
              close={() => dispatch(setSidebarVisible(false))}
              socialIcons={false}
              activePage={activePage}
            />
          </>
        )}
    </div>
  );
};

export default Header;
