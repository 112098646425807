// eslint-disable-next-line import/prefer-default-export
export const socialIcons = [
  {
    name: 'facebook',
    icon: 'fab fa-facebook-square',
    url: 'https://www.facebook.com/lastraperasperu/',
    aria: 'Link a Facebook',
  },
  {
    name: 'instagram',
    icon: 'fab fa-instagram',
    url: 'https://www.instagram.com/lastraperasperu/',
    aria: 'Link a Instagram',
  },
  {
    name: 'whatsapp',
    icon: 'fab fa-whatsapp',
    url: 'https://wa.me/message/IEAC3V6YOR7IJ1',
    aria: 'Link a Whatsapp',
    // url: 'https://wa.me/51959628837?text=Hola.%20Quiero%20que%20me%20avisen%20cuando%20publiquen%20un%20nuevo%20album',
  },
];
