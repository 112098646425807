const messages = [
  { alt: 'candado', emoji: '🔒', text: 'Compra 100% segura' },
  {
    alt: 'camión',
    emoji: '🚚',
    text: 'Elige recojo en tienda o delivery a todo el Perú',
  },
  { alt: 'check', emoji: '✅', text: 'Revisamos las prenda una por una' },
  {
    alt: 'corazón',
    emoji: '💚',
    text: 'Si no estás contenta con tu compra, la devuelves',
  },
  {
    alt: 'tarjeta de crédito',
    emoji: '💳',
    text: 'Aceptamos todas las tarjetas de crédito y débito',
  },
  {
    alt: 'inbox',
    emoji: '📩',
    text: 'Para compras con otros medios de pago, escríbenos',
  },
  { alt: 'vestido', emoji: '👗', text: 'Nuevas prendas todas las semanas' },
];

export default messages;
