import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

import Emoji from '../../Emoji';

const Markdown = dynamic(() => import('../../Markdown'));

const components = {
  p: ({ children }) => (
    <span className="ml-1">{children}</span>
  ),
};

const Message = ({
  children: message,
  changeMessage,
}) => (
  <div className="w-11/12">
    <span
      onClick={changeMessage}
      onKeyPress={changeMessage}
      role="button"
      tabIndex="0"
    >
      <Emoji ariaLabel={message.alt}>{message.emoji}</Emoji>
      <Markdown components={components}>{message.text}</Markdown>
    </span>
  </div>
);

Message.propTypes = {
  children: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    emoji: PropTypes.node.isRequired,
    text: PropTypes.string.isRequired,
  }),
  changeMessage: PropTypes.func,
};

Message.defaultProps = {
  children: undefined,
  changeMessage: undefined,
};

export default Message;
