import React, { useState, useCallback } from 'react';

import useInterval from '../../../lib/hooks/useInterval';
import messages from './messages';
import Message from './Message';

const fadeDuration = 5;

// const SuperHeader = ({ show, setShow }) => {
const SuperHeader = () => {
  const [currMessageIndex, setCurrMessageIndex] = useState(0);

  let resetInterval;

  const changeMessage = useCallback(() => {
    const nextIndex = currMessageIndex < 0 || currMessageIndex + 1 >= messages.length
      ? 0
      : currMessageIndex + 1;
    setCurrMessageIndex(nextIndex);
    if (resetInterval) {
      resetInterval();
    }
  }, [currMessageIndex, resetInterval]);

  resetInterval = useInterval(changeMessage, fadeDuration * 1000);

  const message = messages[currMessageIndex];

  return (
    <div
      className="
      text-white w-full h-8 m-0 p-0 pt-2 sm:pt-1
      text-ellipsis overflow-hidden whitespace-no-wrap bg-black
      hover:cursor-pointer focus:border-0
      z-[2]
      "
    >
      <div className="text-xs sm:text-sm w-full font-bold text-center flex ">
        <Message fadeDuration={fadeDuration} changeMessage={changeMessage}>
          {message}
        </Message>
      </div>
    </div>
  );
};

export default SuperHeader;
