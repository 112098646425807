import React from 'react';
import PropTypes from 'prop-types';
import { SearchBox as AlgoliaSearchBox } from 'react-instantsearch-dom';
import { useRouter } from 'next/router';
import { useStore as useSearchStore } from '../../features/search/store/main';
import { createQueryString } from '../../lib/utils/search';

const SearchBox = ({ className, ref }) => {
  const router = useRouter();
  const { searchState, setSearchState } = useSearchStore();

  const value = searchState.query || '';

  return (
    <>
      <AlgoliaSearchBox
        ref={ref}
        inputId="searchBox"
        className={className}
        searchAsYouType={false}
        showLoadingIndicator
        translations={{
          submitTitle: 'Buscar',
          resetTitle: 'Limpiar buscador',
          placeholder: 'Vestido amarillo con flores...',
        }}
        autocapitalize="none"
        onSubmit={(evt) => {
          evt.preventDefault();

          const query = evt.currentTarget[0].value;
          const searchStateSubmit = {
            query,
          };

          const qs = createQueryString(searchStateSubmit);

          const url = '/shop';
          router.push(`${url}${qs}`);

          setSearchState(searchStateSubmit);
        }}
        onReset={() => {
          setSearchState({});
        }}
        defaultRefinement={value}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="searchBox" className="sr-only">Coloca un texto para buscar</label>
    </>
  );
};

SearchBox.propTypes = {
  className: PropTypes.string,
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

SearchBox.defaultProps = {
  className: '',
  ref: null,
};

export default React.forwardRef((props, ref) => <SearchBox {...props} ref={ref} />);
