import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import muiStyles from './muiStyles';

const useStyles = makeStyles(muiStyles);

const ButtonLT = ({
  children, onClick, style, classes, ...props
}) => {
  const styles = useStyles();
  return (
    <Button
      onClick={onClick}
      style={style}
      classes={{
        root: styles.primaryButton,
        outlined: styles.outlinedButton,
        disabled: styles.disabledButton,
        outlinedPrimary: styles.outlinedPrimary,
        outlinedSecondary: styles.outlinedSecondary,
        sizeLarge: styles.sizeLargeButton,
        ...classes,
      }}
      data-testid="button-lt"
      {...props}
    >
      {children}
    </Button>
  );
};

ButtonLT.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.object,
  classes: PropTypes.object,
};

ButtonLT.defaultProps = {
  onClick: () => null,
  style: {},
  classes: {},
};

export default ButtonLT;
