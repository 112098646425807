import React, { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';

import Header from './Header';

const SubHeader = dynamic(() => import('./SubHeader'));

const HeaderSection = () => {
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);

  useEffect(() => {
    const scrollHandler = () => {
      const isVisible = window.scrollY < 64;
      setIsHeaderVisible(isVisible);
    };
    document.addEventListener('scroll', scrollHandler);
    return () => {
      document.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  return (
    <>
      <div className="sticky bg-white top-0 z-[2] md:relative">
        <Header />
      </div>
      <div className="hidden lg:block sticky bg-white z-[2] mt-0 top-[-2px]">
        <SubHeader header={isHeaderVisible} />
      </div>
    </>
  );
};

export default HeaderSection;
